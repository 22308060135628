<template>
  <div class="footer-buttons">
    <div class="footer-buttons__dangers">
      <rcc-button v-if="dangerText" color="error" :is-loading="isSubmit" @click="$emit('danger-click')">
        {{ dangerText }}
      </rcc-button>

      <rcc-button
        v-if="waningText"
        color="warning"
        :is-loading="isSubmit"
        :is-disabled="isWarningButtonDisabled"
        @click="$emit('warning-click')"
      >
        {{ waningText }}
      </rcc-button>

      <rcc-button v-if="additionalText" color="primary" :is-loading="isSubmit" @click="$emit('additional-click')">
        {{ additionalText }}
      </rcc-button>
    </div>

    <div class="footer-buttons__actions">
      <rcc-button v-if="cancelText" isOutlined color="primary" @click="$emit('cancel-click')">
        {{ cancelText }}
      </rcc-button>

      <slot name="save">
        <rcc-button v-if="saveText" type="submit" @submit="$emit('submit')" color="success" :is-loading="isSubmit">
          {{ saveText }}
        </rcc-button>
      </slot>
    </div>
  </div>
</template>

<script>
import RccButton from 'Components/controls/rcc-button.vue'

export default {
  components: { RccButton },

  name: 'RccFooterButtons',

  props: {
    dangerText: {
      type: String,
      default: ''
    },

    waningText: {
      type: String,
      default: ''
    },

    additionalText: {
      type: String,
      default: ''
    },

    cancelText: {
      type: String,
      default: 'Отмена'
    },

    saveText: {
      type: String,
      default: 'Сохранить'
    },

    isSubmit: {
      type: Boolean,
      default: false
    },

    isWarningButtonDisabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">

.footer-buttons {
  display: flex;
  justify-content: space-between;
  padding: 30px;
  background: white;

  &__dangers,  &__actions {
    display: flex;
    gap: 10px;
  }
}
</style>
