<template>
  <v-alert
    v-bind="$attrs"
    :type="type"
    :class="classes"
    v-on="$listeners"
  >
    <slot />
  </v-alert>
</template>

<script>
export default {
  name: 'RccAlert',

  inheritAttrs: false,

  props: {
    type: {
      type: String,
      default: 'info'
    },

    fitContent: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    classes() {
      return [
        'alert',
        this.fitContent && 'alert_fit-content'
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.alert {
  &_fit-content {
    width: fit-content;
  }
}
</style>
